






























import Vue from "vue";
import SubHeadline from "@/components/SubHeadline.vue";

export default Vue.extend({
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [], // all of the tabs
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
  },
  beforeUpdate() {
    this.updateTabs();
    this.selectTab(this.selectedIndex);
  },
  mounted() {
    this.updateTabs();
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
    updateTabs() {
      const childs = this.$children;
      // Tabs are only children of Type Tab
      this.tabs = childs.filter((tab) => {
        if (tab.$options.name === "Tab") {
          return tab;
        }
      });
    },
  },
  components: {
    SubHeadline,
  },
});
