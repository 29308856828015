






import Vue from "vue";
export default Vue.extend({
  name: "Tab",
  props: {
    title: {
      type: String,
      default: "Tab",
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
});
