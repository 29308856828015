




























































































import Vue from "vue";
import gql from "graphql-tag";
import Accessory from "./Accessory";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";
import ContentEditor from "@/components/form/ContentEditor.vue";

export default Vue.extend({
  apollo: {
    pdb_wheels_by_pk: {
      // GraphQL Query
      query: gql`
        query pdb_accessories_by_pk($id: bigint!) {
          pdb_accessories_by_pk(id: $id) {
            id
            name
            item_number
            image
            image_dir
            price
            tag
            description
            disclaimer_id
            savety_related
            category_id
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          id: this.$route.params.accessoryID,
        };
      },
      skip() {
        // Skip request if no model id is provided in creation mode
        return this.skipQuery;
      },
      update(data) {
        console.log(data);
        // The returned value will update
        // the vue property 'pingMessage'
        return data.pdb_accessories_by_pk;
      },
      result({ data, loading }) {
        if (!loading) {
          console.log(data);
          this.pdb_accessory = data.pdb_accessories_by_pk;
        }
      },
    },
    pdb_disclaimers: {
      query: gql`
        query pdb_disclaimers {
          pdb_disclaimers {
            id
            name
          }
        }
      `,
      update(data) {
        console.log(data);
        if (data.pdb_disclaimers[0].id) {
          const emptyVal = { id: null, name: "Kein Disclaimer" };
          data.pdb_disclaimers.unshift(emptyVal);
        }
        return data.pdb_disclaimers;
      },
    },
    pdb_categories: {
      query: gql`
        query pdb_categories {
          pdb_categories(where: { category_type: { _eq: "accessory" } }) {
            id
            name
          }
        }
      `,
      update(data) {
        console.log(data);

        return data.pdb_categories;
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_accessory: {} as Accessory,
    };
  },
  computed: {
    selectedDisclaimerID: {
      get(): string {
        return `${this.pdb_accessory.disclaimer_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_accessory.disclaimer_id = newID;
      },
    },
    selectedCategoryID: {
      get(): string {
        return `${this.pdb_accessory.category_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_accessory.category_id = newID;
      },
    },
  },
  created() {
    // Get model if model id is provided
    if (this.$route.params.accessoryID) {
      this.skipQuery = false;
    }
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      const objectToStore: Accessory = Object.assign({}, this.pdb_accessory);
      delete objectToStore.__typename;
      console.log(objectToStore);

      if (!objectToStore.image_dir) {
        objectToStore.image_dir = "uploads/accessory/image";
      }

      if (!objectToStore.id) {
        // Create new model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($objects: [pdb_accessories_insert_input!]!) {
                insert_pdb_accessories(objects: $objects) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              objects: objectToStore,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$router.push("/accessories");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      } else {
        // Update model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation(
                $set: pdb_accessories_set_input
                $pk: pdb_accessories_pk_columns_input!
              ) {
                update_pdb_accessories_by_pk(_set: $set, pk_columns: $pk) {
                  id
                }
              }
            `,
            // Parameters
            variables: {
              set: objectToStore,
              pk: { id: objectToStore.id },
            },
          })
          .then((data) => {
            // Result
            console.log(data);

            this.$router.push("/accessories");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    cancel() {
      this.$router.push("/accessories");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
    ContentEditor,
  },
});
